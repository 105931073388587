import React from 'react';
import PropTypes from 'prop-types';
import {Link, Redirect} from 'react-router-dom'

//MUI imports
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'

const token = localStorage.IdToken;


class Navbar extends React.Component{
    state = {
        navigate: false
    }

    logout = () => {
        localStorage.clear(token)
        this.setState({navigate: true})
    }
    
    render() {
        const { navigate } = this.state

        if (navigate){
            return <Redirect to="/" push={true} />
        }
        return (
            <AppBar>
                <Toolbar className="nav-container">
                    {/* <Button onClick = {this.logout} color="inherit" component={Link} to="/">Logout</Button> */}
                    <Button color="inherit" component={Link} to="/">DocumentDetails</Button>
                    <Button color="inherit" component={Link} to="/batchdetails">BatchDetails</Button>
                </Toolbar>
            </AppBar>
        )
    }
}

export default Navbar;
import React from 'react';
import PropTypes from 'prop-types';

import Navbar from './Navbar'
import LoginForm from './LoginForm'

//MUI imports
import Button from '@material-ui/core/Button'

function Nav(props) {
  return (
      <div>
        {/* <Navbar/> */}
        <h1>JBTS Document Processing</h1>
        <LoginForm/>
      </div>
  )
}

export default Nav;

Nav.propTypes = {
  logged_in: PropTypes.bool.isRequired,
  display_form: PropTypes.func.isRequired,
  handle_logout: PropTypes.func.isRequired
};
import React, { Component } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import RefreshIcon from "@material-ui/icons/Refresh";
// import { formatDate } from "./helpers/date";
import UploadDailogBoxDemo from "./UploadDailogBox";
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyValueDialog from './KeyValueDetail';
import Navbar from './Navbar'
import ENV_URLS from '../environments/env';

const toolbarStyles = theme => ({
  info: {
    color: "#9e9e9e",
    fontSize: ".7em"
  },
  infoTitle: {
    fontSize: "0.9em"
  },
  notice_update: {
    marginRight: "10px"
  },
  title: {},
  spacer: {
    flex: "1 1 10%"
  },
  section: {
    color: "red",
    fontSize: ".7em",
    textAlign: 'initial',
  }
});

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  }
});

const rows = [
  { id: "mstr_doc_id", label: "Document Id" },
  { id: "filename", label: "Document Name" },
  { id: "client_id", label: "Client Id" },
  { id: "process_id", label: "Process Id" },
  { id: "uploaded_by", label: "Uploaded By" },
  { id: "upload_time", label: "Upload Time" },
  { id: "status_id", label: "Current Status" },
  { id: "openDetails", label: "Details" }
];


const headStyle = theme => ({
  head: {
    backgroundColor: "#f7f7f7",
    borderTop: "1px solid #e0e0e0"
  }
});

function sorting(a, b, order) {
  if (order === "asc") {
    return a > b;
  }
  if (order === "desc") {
    return b > a;
  }
  return 0;
}

let EnhancedTableToolbar = props => {
  const { classes, click, date } = props;
  return (
    <div>
      <Navbar />
      <Toolbar>
        <div className={classes.title}>
          <Typography variant="h6" className={classes.section}>
            Documents
          </Typography>
          <Typography variant="h5" id="statistics">
            Statistics
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Refresh">
            <IconButton aria-label="Refresh" onClick={click}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </div>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy, classes } = this.props;
    return (
      <TableHead className={classes.head}>
        <TableRow>
          {rows.map(row => (
            <TableCell
              key={row.id}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead = withStyles(headStyle)(EnhancedTableHead);

class DataTable extends Component {
  state = {
    documentsData: [],
    today: new Date(),
    order: "asc",
    orderBy: "InterfaceName",
    checkedA: false,
    keyValueData: [],
    docChildData: [],
    openKeyValueDialog: false,
    selectedKeyValue: null
  }

  onRefresh = () => {
    this.setState({
      today: new Date(),
      order: "asc",
      orderBy: "InterfaceName"
    });
  };

  handleChangeSwitch = () => {
    this.setState({
      checkedA: !this.state.checkedA
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  componentDidMount() {
    this.getDocumentMasterDetails()

    //Automatically refresh after 5sec
    this.intervalID = setInterval(this.getDocumentMasterDetails.bind(this), 111115000)

  }

  componentWillUnmount() {
    //stop getDocumentMasterDetails to run after unmounting this component
    clearInterval(this.intervalID)
  }

  getDocumentMasterDetails() {
    console.log(ENV_URLS.baseURL)
    axios.get(ENV_URLS.baseURL + 'api/docmaster/')
      .then(res => {
        console.log(res.data.doc_mstr_data)
        this.setState({
          documentsData: res.data.doc_mstr_data
        });
      })
      .catch(err => console.log(err))
  }

  getKeyValueDetails(document_id) {
    console.log(ENV_URLS.baseURL)
    console.log("===key value triggered===", document_id)
    axios.get(ENV_URLS.baseURL + 'api/doc_kv/' + document_id)
      .then(res => {
        console.log("Key Value Data ==> ", res.data)
        this.setState({
          keyValueData: res.data.doc_kv_data
        })
      })
      .catch(err => console.log(err))
  }

  getDocChildDetails(document_id) {
    console.log("===key value triggered===", document_id)
    axios.get(ENV_URLS.baseURL + 'api/doc_child/' + document_id)
      .then(res => {
        console.log("Doc Child Data ==> ", res.data)
        this.setState({
          docChildData: res.data.doc_child_data
        })
      })
      .catch(err => console.log(err))
  }

  handleKeyValueOpen = (document_id) => {
    this.getDocChildDetails(document_id);
    this.getKeyValueDetails(document_id);
    this.setState({
      openKeyValueDialog: true
    })
  };

  handleKeyValueClose = (value) => {
    console.log('value', value)
    this.setState({
      openKeyValueDialog: false,
      selectedKeyValue: value
    })
  };

  handleStartOperations = (value) => {
    console.log(value)
  }
  render() {
    const { classes } = this.props;
    const { today, orderBy, order } = this.state;
    return (
      <div>

        <div>
          {/* <Button variant="outlined" color="primary" onClick={this.handleKeyValueOpen}>
            Open KeyValue
          </Button> */}
          <KeyValueDialog selectedValue={this.state.keyValueData} docChildvalue={this.state.docChildData} open={this.state.openKeyValueDialog} onClose={this.handleKeyValueClose} />
        </div>

        <UploadDailogBoxDemo />

        <EnhancedTableToolbar date={today} click={this.onRefresh} />
        <Paper className={classes.root}>
          <Table style={{ height: '500px', overflow: 'scrollbar' }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {this.state.documentsData
                .sort((a, b) => b.upload_time > a.upload_time ? 1 : -1)
                .map((row, id) => (
                  <TableRow key={`data-${id}`} hover>
                    <TableCell>{row.mstr_doc_id}</TableCell>
                    <TableCell>{row.filename}</TableCell>
                    <TableCell>{row.client_id}</TableCell>
                    <TableCell>{row.process_id}</TableCell>
                    <TableCell>{row.uploaded_by}</TableCell>
                    <TableCell>{dayjs(row.upload_time).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                    <TableCell>{row.status_id}</TableCell>
                    {/* <TableCell>{row.CurrentStatus}</TableCell> */}
                    <TableCell>
                      {/* <button onClick={this.handleStartOperations.bind(this, row.document_id)}>Start</button> */}
                      <button onClick={this.handleKeyValueOpen.bind(this, row.mstr_doc_id)}>View</button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>


          {/* <div>
            <p>{this.state.keyValueData.child__document__document_id}</p>
            <p>{this.state.keyValueData.keyValue && this.state.keyValueData.keyValue.map((keyValue, index) => {
              return (
                <ul key={index}>
                  <li>{keyValue.Key}</li>
                  <li>{keyValue.Value}</li>
                </ul>
              )
            })
            }</p>
          </div> */}

        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(DataTable);

import React, { Component } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import RefreshIcon from "@material-ui/icons/Refresh";
// import { formatDate } from "./helpers/date";
import UploadDailogBoxDemo from "./UploadDailogBox";
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyValueDialog from './KeyValueDetail';
import Navbar from './Navbar'
import ENV_URLS from '../environments/env';

const toolbarStyles = theme => ({
  info: {
    color: "#9e9e9e",
    fontSize: ".7em"
  },
  infoTitle: {
    fontSize: "0.9em"
  },
  notice_update: {
    marginRight: "10px"
  },
  title: {},
  spacer: {
    flex: "1 1 10%"
  },
  section: {
    color: "red",
    fontSize: ".7em",
    textAlign: 'initial',
  }
});

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  }
});

const rows = [
  { id: "load_batch_id", label: "Load Batch Id" },
  { id: "create_time", label: "Created at" },
  { id: "schedule_id", label: "Schedule Id" },
  { id: "process_id", label: "Process Id" },
  { id: "num_of_files", label: "Files in batch" },
  { id: "files_processed", label: "Processed files" },
  { id: "files_exception", label: "Exception files" },
  { id: "start_time", label: "Started at" },
  { id: "end_time", label: "Ended at" },
  { id: "status", label: "Current Status" }
];


const headStyle = theme => ({
  head: {
    backgroundColor: "#f7f7f7",
    borderTop: "1px solid #e0e0e0"
  }
});

function sorting(a, b, order) {
  if (order === "asc") {
    return a > b;
  }
  if (order === "desc") {
    return b > a;
  }
  return 0;
}

let EnhancedTableToolbar = props => {
  const { classes, click, date } = props;
  return (
    <div>
      <Navbar />
      <Toolbar>
        <div className={classes.title}>
          <Typography variant="h6" className={classes.section}>
            Documents
          </Typography>
          <Typography variant="h5" id="statistics">
            Load Distribution Table
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Refresh">
            <IconButton aria-label="Refresh" onClick={click}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </div>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy, classes } = this.props;
    return (
      <TableHead className={classes.head}>
        <TableRow>
          {rows.map(row => (
            <TableCell
              key={row.id}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead = withStyles(headStyle)(EnhancedTableHead);

class DataTable extends Component {
  state = {
    loadDistData: [],
    today: new Date(),
    order: "asc",
    orderBy: "InterfaceName",
    checkedA: false,
    keyValueData: [],
    openKeyValueDialog: false,
    selectedKeyValue: null
  }

  onRefresh = () => {
    this.setState({
      today: new Date(),
      order: "asc",
      orderBy: "InterfaceName"
    });
  };

  handleChangeSwitch = () => {
    this.setState({
      checkedA: !this.state.checkedA
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  componentDidMount() {
    this.getBatchDetails()

    //Automatically refresh after 5sec
    this.intervalID = setInterval(this.getBatchDetails.bind(this), 30000)
  }

  componentWillUnmount() {
    //stop getDocumentMasterDetails to run after unmounting this component
    clearInterval(this.intervalID)
  }

  getBatchDetails() {
    console.log(ENV_URLS.baseURL)
    axios.get(ENV_URLS.baseURL + 'api/loadmaster/')
      .then(res => {
        console.log("Batch Data ==> ", res.data)
        this.setState({
          loadDistData: res.data.load_dist_data
        });
      })
      .catch(err => console.log(err))
  }

  render() {
    const { classes } = this.props;
    const { today, orderBy, order } = this.state;
    return (
      <div>
        <EnhancedTableToolbar date={today} click={this.onRefresh} />
        <Paper className={classes.root}>
          <Table style={{ height: '500px', overflow: 'scrollbar' }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {this.state.loadDistData
                .sort((a, b) => b.ended_at > a.ended_at ? 1 : -1)
                .map((row, id) => (
                  <TableRow key={`data-${id}`} hover>
                    <TableCell>{row.load_batch_id}</TableCell>
                    <TableCell>{dayjs(row.create_time).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                    <TableCell>{row.schedule_id}</TableCell>
                    <TableCell>{row.process_id}</TableCell>
                    <TableCell>{row.num_of_files}</TableCell>
                    <TableCell>{row.files_processed}</TableCell>
                    <TableCell>{row.files_exception}</TableCell>
                    <TableCell>{dayjs(row.start_time).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                    <TableCell>{dayjs(row.end_time).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(DataTable);

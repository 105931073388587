import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from "@material-ui/icons/MoveToInbox";

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import { Divider } from '@material-ui/core';
import ENV_URLS from '../environments/env';


const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    paper:
    {
        minWidth: "700px"
    },
    closeButton: {
        position: 'absolute',
        left: '90%'
    },
});

const useUploadStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    startButton: {
        margin: '0px 10px'
    }
}));

function UploadDailogBox(props) {

    var [listFiles, setListFiles] = React.useState([]);
    var [uploadedDocuments, setuploadedDocuments] = React.useState([]);
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
    const [disableUpload, setEnableUpload] = React.useState(false);
    const [disableStart, setEnableStart] = React.useState(false);
    const [disableLoading, setEnableLoading] = React.useState(false);
    const classesUploadButton = useUploadStyles();
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleChangeFile = (event) => {
        var files = Array.from(event.target.files);
        setListFiles(listFiles.concat(files));
        console.log(listFiles)
        setEnableUpload(true);
    }

    function submitUploadedFiles(process_id) {
        setEnableLoading(true)
        setEnableUpload(false)
        const data = new FormData()
        let fileNames = []
        for (var x = 0; x < listFiles.length; x++) {
            data.append('file', listFiles[x]);
            fileNames.push(listFiles[x].name)
        }
        console.log(fileNames)
        console.log("Request sent", ENV_URLS.baseURL)
        axios.post(ENV_URLS.baseURL + 'api/upload/files/', data)
            .then(res => {
                console.log(res.data)
                console.log(listFiles.length)
                setEnableLoading(false)
                setEnableUpload(false)
                setuploadedDocuments([])
                setListFiles([])
                let d = new Date()
                let create_request_id = d.getDate() + (d.getMonth() + 1) + d.getFullYear() +
                    d.getHours() + d.getMinutes() + d.getMilliseconds();
                let body = { 'process_id': process_id, 'client_id': 'Ashish', 'uploaded_by': 'SYSTEM', 'request_id': create_request_id, 'file_names': fileNames }
                axios.post(ENV_URLS.baseURL + 'api/document-master', body)
                    .then(res => {
                        console.log(res.data)
                        // setuploadedDocuments(uploadedDocuments.concat(res.data))
                        // setEnableStart(true)
                    })
                    .catch(err => console.log(err.res))
            })
            .catch(err => console.log(err.res))
    }


    function submitInstantUploadedFiles(process_id) {
        setEnableLoading(true)
        setEnableUpload(false)
        const data = new FormData()
        for (var x = 0; x < listFiles.length; x++) {
            data.append('file', listFiles[x]);
        }
        console.log(listFiles.length)
        console.log("Request sent")
        axios.post(ENV_URLS.baseURL + 'api/upload/files/', data)
            .then(res => {
                console.log(res.data)
                console.log(listFiles.length)
                setEnableLoading(false)
                setEnableUpload(false)
                setuploadedDocuments([])
                setListFiles([])
                let body = { 'process_id': process_id }
                axios.post(ENV_URLS.baseURL + 'api/adhoc_doc_process/', body)
                    .then(res => {
                        console.log(res.data)
                        // setuploadedDocuments(uploadedDocuments.concat(res.data))
                        // setEnableStart(true)
                    })
                    .catch(err => console.log(err.res))
            })
            .catch(err => console.log(err.res))
    }

    // const handleStartOperation = () => {
    //     setEnableLoading(true)
    //     setEnableStart(false)
    //     setEnableUpload(false)
    //     axios.post(ENV_URLS.baseURL +'api/startoperations/', uploadedDocuments)
    //         .then(res => {
    //             console.log(res.data)
    //             setuploadedDocuments([])
    //             setListFiles([]);
    //             setEnableLoading(false)
    //             setEnableStart(false)
    //             setEnableUpload(false)
    //         })
    //         .catch(err => console.log(err))
    // }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} classes={{ paper: classes.paper }} >
            <Tooltip title="Close" classes={{ closeButton: classes.closeButton }} placement="top">
                <IconButton onClick={handleClose} >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
            <DialogTitle id="simple-dialog-title"><span>UPLOAD FILES</span></DialogTitle>

            <Container maxWidth="md">
                <input
                    accept="image/*"
                    className={classesUploadButton.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleChangeFile}
                />
                <label htmlFor="contained-button-file">
                    <Button disabled={disableLoading} variant="contained" color="primary" component="span">
                        Browse
                    </Button>
                </label>

                <Button disabled={!disableUpload} onClick={() => submitUploadedFiles('Batch')} variant="contained" color="primary" className={classesUploadButton.startButton}>
                    Batch Upload
                    {disableLoading && (<CircularProgress className={classes.progress} />)}
                </Button>

                <Button disabled={!disableUpload} onClick={() => submitUploadedFiles('Adhoc_Process')} variant="contained" color="primary" className={classesUploadButton.startButton}>
                    Instant Upload
                    {disableLoading && (<CircularProgress className={classes.progress} />)}
                </Button>
                {/* <Button disabled={!disableStart} onClick={handleStartOperation} variant="contained" color="primary" className={classesUploadButton.startButton}>
                    Start                
                    {disableLoading && (<CircularProgress className={classes.progress}/>)}
            </Button> */}

            </Container>

            <Container maxWidth="md">

                <ul>
                    {listFiles && listFiles.map((name, index) => {
                        return <li key={index}>

                            <div id="upload-box">
                                <p><strong>Filename: </strong>{name.name}</p>
                                <p><strong>File type: </strong>{name.type}</p>
                                <p><strong>File size: </strong>{name.size} bytes</p>
                                {name && <ImageThumb image={name} />}
                            </div>
                        </li>;
                    })}
                </ul>


            </Container>


        </Dialog >
    );
}

UploadDailogBox.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

/**
 * Component to display thumbnail of image.
 */
const ImageThumb = ({ image }) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} style={{ maxWidth: '600px' }} />;
};


export default function UploadDailogBoxDemo() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Upload Files
            </Button>
            <UploadDailogBox selectedValue={selectedValue} open={open} onClose={handleClose} />
        </div>
    );
}

import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode'

// import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import DataTable from './components/DataTable';
import Home from './components/Home';
import BatchDataTable from './components/BatchDataTable';
import AuthRoute from './util/AuthRoute'

import "./styles.css";


// let authenticated;
// const token = localStorage.IdToken;
// if(token){
//   const decodedToken = jwtDecode(token);
//   if(decodedToken.exp * 1000 < Date.now()){
//     window.location.href = '/login'
//     authenticated = false
//   }
//   else {
//     authenticated = true
//   }
// }

class App extends Component {
  render() {

    return (
      <div  className="App">        
          <Router>
          {/* <Navbar/> */}
            <div className="container">
              <Switch>
                  {/* <Route exact path='/' component={Home}/> */}
                  {/* <AuthRoute exact path='/login' component={LoginForm} authenticated = {authenticated}/>
                  <AuthRoute exact path='/signup' component={SignupForm} authenticated = {authenticated}/> */}
                  <Route exact path='/' component={DataTable} />
                  <Route exact path='/batchdetails' component={BatchDataTable} />
              </Switch>
            </div>
          </Router>
      </div>
      
    );
  }
}

export default App;



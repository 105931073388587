import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router'

//MUI imports
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import ENV_URLS from '../environments/env';

const styles = {
    card: {
        minWidth: '450px',
        marginTop: 60
    },
    form: {
        textAlign: 'center'
    },
    pageTitle: {
        margin: '10px auto'
    },
    textfield: {
        margin: '10px auto'
    },
    button: {
        width: '100%',
        margin: '20px 0',
        position: 'relative'
    },
    progress: {
        position: 'absolute',
    },
    customError: {
        color: 'red',
        fontSize: '1rem',
        marginTop: 10
    }
}

class LoginForm extends React.Component {

    state = {
        username: '',
        password: '',
        loading: false,
        errors: {},
        redirectTable: false
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleSubmit = e => {
        e.preventDefault()
        this.setState({
            loading: true
        })
        const userData = {
            username: this.state.username,
            password: this.state.password
        }
        axios.post(ENV_URLS.baseURL +'token-auth/', userData)
            .then(res => {
                console.log(res.data)
                localStorage.setItem('IdToken', res.data.token)
                this.setState({
                    loading: false,
                    redirectTable: true
                })
                // this.props.history.push('/tables')
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    errors: err.response.data,
                    loading: false
                })
            })
    }

    render() {
        const { classes } = this.props
        const { errors, loading, redirectTable } = this.state

        if (redirectTable) {
            return <Redirect to='/tables' />;
        }


        return (
            <Grid container>
                <Grid item sm />
                <Grid item sm>
                    <Card className={classes.card} variant="outlined">
                        <CardContent>
                            <form noValidate onSubmit={this.handleSubmit}>
                                <Typography variant="h3" className={classes.pageTitle}>Login</Typography>
                                <TextField
                                    id="username"
                                    type="text"
                                    name="username"
                                    label="UserName"
                                    helperText={errors.username}
                                    error={errors.username ? true : false}
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    className={classes.textfield}
                                    fullWidth
                                />
                                <TextField
                                    id="password"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    helperText={errors.password}
                                    error={errors.username ? true : false}
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    className={classes.textfield}
                                    fullWidth
                                />
                                {errors.non_field_errors && (
                                    <Typography variant="body2" className={classes.customError}>
                                        Wrong Credentials. Try again.
                                    </Typography>
                                )}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    disabled={loading}
                                >
                                    Login
                            {loading && (
                                        <CircularProgress size={30} className={classes.progress} />
                                    )}
                                </Button>
                                <br />
                                <small>Don't have an account. Sign up <Link to="/signup">here</Link></small>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm />
            </Grid>
        );
    }
}

export default withStyles(styles)(LoginForm);
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    paper: {
        minWidth: "1150px"
    },
    closeButton: {
        position: 'absolute',
        left: '90%'
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function KeyValueDialog(props) {
    const classes = useStyles();
    const { onClose, open, selectedValue, docChildvalue } = props;
    console.log('Selected Value ==> ', selectedValue)

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };


    return (

        <Dialog classes={{ paper: classes.paper }} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <Tooltip title="Close" classes={{ closeButton: classes.closeButton }} placement="top">
                <IconButton onClick={handleClose} >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Document Key Value
            </DialogTitle>
            <DialogContent dividers>
                {docChildvalue.map((item, index) => (
                    <div key={index}>
                        <h5>{item.child_doc_id}</h5>
                        <Typography gutterBottom>
                            Document Id : {item.mstr_doc_id}
                        </Typography>
                        <Typography gutterBottom>
                            Batch Id : {item.load_batch_id}
                        </Typography>
                        <Typography gutterBottom>
                            Child Id : {item.child_doc_id}
                        </Typography>
                        <Typography gutterBottom>
                            Child Name : {item.childname}
                        </Typography>
                        <Typography gutterBottom>
                            Created Time : {dayjs(item.create_time).format('DD/MM/YYYY hh:mm:ss')}
                        </Typography>
                        <Typography gutterBottom>
                            Created By : {item.created_by}
                        </Typography>
                        <Typography gutterBottom>
                            Current Status : {item.status_id}
                        </Typography>
                        <div>
                            <img src={item.image_url} alt="Document url" style={{ width: '725px', height: '850px' }} />
                        </div>

                    </div>
                ))}


                {selectedValue.map((item, index) => (
                    <div key={index}>
                        <TableContainer component={Paper}>
                            <Grid container>
                                {/* <Grid item sm={8}>
                                    <img src={item.image_url} alt="Document url" style={{ width: '725px', height: '850px' }} />
                                </Grid> */}
                                <Grid item sm={4}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"><strong>Key</strong></TableCell>
                                                <TableCell align="right"><strong>Value</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {item.key_value && item.key_value.map((row) => ( */}
                                            <div>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">InvNum</TableCell>
                                                    <TableCell align="right">{item.inv_num}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">InvDate</TableCell>
                                                    <TableCell align="right">{item.inv_date}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">PoNum</TableCell>
                                                    <TableCell align="right">{item.po_num}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">DueDate</TableCell>
                                                    <TableCell align="right">{item.due_date}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">TRN1</TableCell>
                                                    <TableCell align="right">{item.trn_num1}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">TRN2</TableCell>
                                                    <TableCell align="right">{item.trn_num2}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Currency</TableCell>
                                                    <TableCell align="right">{item.currency}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Total</TableCell>
                                                    <TableCell align="right">{item.total}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Tax</TableCell>
                                                    <TableCell align="right">{item.tax}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Organization From</TableCell>
                                                    <TableCell align="right">{item.org_from}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Organization To</TableCell>
                                                    <TableCell align="right">{item.org_to}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Invoice Class</TableCell>
                                                    <TableCell align="right">{item.class_id}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Added at</TableCell>
                                                    <TableCell align="right">{dayjs(item.added_at).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                                                </TableRow>
                                                <TableRow key={item.name}>
                                                    <TableCell align="left">Added by</TableCell>
                                                    <TableCell align="right">{item.added_by}</TableCell>
                                                </TableRow>
                                            </div>
                                            {/* ))} */}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </TableContainer>
                    </div>
                ))}

            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const ImageThumb = ({ image }) => {
    return <img src={URL.createObjectURL(image)} alt={image.name} style={{ maxWidth: '400px' }} />;
};

KeyValueDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};